<template>
  <div>
    <All
      v-if="pwaMode"
    />
    <Single
      v-else
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Done',
  components: {
    All: lazyLoad('views/Done/All'),
    Single: lazyLoad('views/Done/Single'),
  },
  computed: {
    ...mapGetters('app', [
      'pwaMode',
    ]),
  },
};
</script>
